// 法语
export default {
  reward2: {
    desc: {
      title1: 'Nœuds DeepLink',
      text1: 'Seuls les propriétaires de nœuds peuvent participer au minage de trafic CPU et au minage de machines GPU personnelles. La récompense totale est de 12% en jetons DLC, dont 4% pour les nœuds de trafic CPU et 8% pour les nœuds GPU personnels. Le nombre total de jetons est de 12 milliards. La production par nœud dépend du nombre de nœuds effectivement vendus. Si tous sont vendus, chaque nœud produira 100 000 DLC. Si seulement 10% des nœuds sont vendus, chaque nœud produira 1 million de DLC.',
      title2: 'Nombre total de nœuds: 120 000, pas d’émission supplémentaire',
      text2: 'Airdrop supplémentaire par nœud',
      text3: 'Quantité minimale de DLC minée par nœud',
      text4: 'Prix initial',
      text5: 'Prix maximum'
    },
    btn_text: 'Acheter des nœuds',
    table: {
      tier: 'Niveau',
      license: 'Prix de la licence de nœud (USDT)',
      total_num: 'Total des licences',
      total_usdt: 'Total USDT',
      buy: 'Acheter'
    },
    intro: {
      title: 'Introduction au minage de nœuds',
      text1_1: 'Plus le réseau DeepLink a d’utilisateurs, plus il nécessite de trafic réseau et de calculs, ce qui entraîne un besoin accru de machines de trafic CPU et de machines de calcul GPU pour fournir l’infrastructure au réseau DeepLink. Cela permet la décentralisation et aide DeepLink à se développer plus rapidement. En achetant une licence de nœud, vous avez la possibilité de participer au minage et de gagner des récompenses. Vous avez des questions ? Venez',
      text1_2: 'et',
      text1_3: 'discutez avec nous ci-dessus.',
      text2: "Chaque nœud est émis sous la forme d'un NFT et reçoit en plus un airdrop de 30 000 DLC avec la chaîne BSC. L'airdrop commence avant la cotation sur la bourse, avec 20 % des DLC airdrop débloqués immédiatement et 80 % débloqués sur 8 mois. \n (Afficher le processus de jeton DLC : ouvrez le portefeuille et sélectionnez le réseau BSC, sélectionnez Ajouter un jeton et saisissez l'adresse du contrat : 0xD610Be30b06A3828A27D608a38F1E85faAFdEDcC)",
      text3: 'Les nœuds ne peuvent pas être revendus à d’autres avant le 31 octobre 2024.',
      text4: '25% des tokens minés sont débloqués immédiatement, tandis que 75% sont débloqués linéairement sur 150 jours.',
      text5: 'Si le minage ne commence pas dans les 10 jours suivant la cotation, les récompenses seront distribuées sous forme d’airdrops, qui auront lieu tous les 7 jours. Les règles des airdrops sont les mêmes que celles du minage, avec une production totale quotidienne de 4,1 millions de DLC, répartie également en fonction du nombre de nœuds vendus. 25% sont débloqués immédiatement, et 75% sont débloqués linéairement sur 150 jours. Si le minage a déjà commencé, des machines correspondantes seront nécessaires pour recevoir des récompenses.',
      text6: "Les investisseurs qui achètent plus de 1 000 nœuds individuellement bénéficieront d'un ensemble complet de supports pour l'exploitation des nœuds, ainsi que de la possibilité de s'arrimer à un opérateur coopératif pour aider à gérer les nœuds."
    },
    question: {
      ques1: 'Combien de NFT de nœuds sont vendus au total ?',
      ans1: 'Un total de 120 000',
      ques2: 'Combien coûte chaque NFT de nœud ?',
      ans2: 'Il est divisé en 10 niveaux, avec des prix allant de 30U à 155U, vendus sur le site officiel jusqu’à épuisement des stocks',
      ques3: 'L’achat d’un NFT de nœud est-il un paiement unique ?',
      ans3: 'Oui, l’achat d’un nœud est un paiement unique, aucun frais supplémentaire n’est requis à l’avenir',
      ques4: 'Y a-t-il des airdrops supplémentaires lors de l’achat d’un NFT de nœud ?',
      ans4: 'Oui, chaque propriétaire de nœud reçoit un airdrop de 30 000 DLC. L’airdrop commence avant la cotation en bourse, avec 20% des DLC débloqués immédiatement et 80% débloqués sur 8 mois',
      ques5: 'Quel est le mécanisme de minage spécifique ?',
      ans5: 'La récompense totale est de 12 milliards de DLC. Les revenus totaux pour les nœuds de trafic sont de 4 milliards de DLC, et les revenus totaux pour les nœuds de machines GPU personnelles sont de 8 milliards de DLC. En achetant un NFT de nœud, vous pouvez participer simultanément aux deux types de minage. La récompense pour les 4 premières années est de 1,5 milliard de DLC par an, avec une réduction de moitié tous les 4 ans. La récompense totale quotidienne est de 4,11 millions de DLC, distribuée uniquement aux propriétaires de NFT de nœuds. 25% des jetons minés sont débloqués immédiatement, 75% sont débloqués linéairement sur 150 jours',
      ques6: 'Un NFT de nœud acheté peut-il participer simultanément au minage de machine à trafic CPU et au minage de machine GPU personnelle ?',
      ans6: 'Oui',
      ques7: 'Qu’est-ce qu’un nœud de machine à bande passante CPU ?',
      ans7: 'Le nœud de machine à bande passante CPU assume la tâche principale de calcul et de transmission du trafic dans le réseau DeepLink. Grâce au modèle de minage, DeepLink permet à un plus grand nombre de personnes de participer et de gagner des récompenses. Les revenus totaux pour les nœuds de trafic sont de 4 milliards de DLC',
      ques8: 'Quelle est la récompense totale pour les machines à bande passante au cours de la première année ?',
      ans8: 'La récompense pour la première année est de 500 millions de DLC, avec environ 1,37 million de DLC récompensés chaque jour',
      ques9: 'Comment la bande passante de chaque nœud de machine à bande passante CPU est-elle liée à ses revenus ?',
      ans9: 'La bande passante de base est de 10Mb en bande passante montante. La valeur de la bande passante est de 10 pour une bande passante montante de 10Mb. La bande passante n’est valide qu’en multiples de 10, par exemple, une bande passante montante de 45Mb est calculée comme 40 en valeur de bande passante. La bande passante augmente de 30% selon l’utilisation réelle. Enfin, les revenus sont répartis en fonction de la valeur réelle de la bande passante',
      ques10: 'Les exigences de configuration pour les nœuds de machine à bande passante sont-elles élevées ? Y a-t-il des informations de configuration ? Comment participer au minage ?',
      ans10: 'Les exigences de configuration ne sont pas élevées. vCPU 1C + 2G de mémoire + 50G de disque dur. La location sur AWS coûte environ 10 USD par mois, la bande passante est facturée en fonction de l’utilisation réelle. Après avoir loué une machine virtuelle CPU sur AWS, installez le programme de minage de nœuds de trafic DeepLink, créez une adresse de portefeuille, transférez le NFT de nœud dans le portefeuille et activez la fonction de minage pour participer au minage',
      ques11: 'Qu’est-ce qu’un nœud de machine GPU personnelle ?',
      ans11: 'Les particuliers peuvent offrir leurs ordinateurs GPU pour participer au minage. Les machines GPU fournissent des services d’infrastructure de jeu en nuage pour le protocole DeepLink. Les revenus totaux pour les nœuds de machines GPU personnelles sont de 8 milliards de DLC',
      ques12: 'Quelle est la récompense totale pour les nœuds de machines GPU personnelles au cours de la première année ?',
      ans12: 'La récompense pour la première année est de 1 milliard de DLC, avec environ 2,74 millions de DLC récompensés chaque jour',
      ques13: 'Quelles sont les exigences de configuration pour les nœuds de machines GPU personnelles ? Comment participer au minage ?',
      ans13: 'Les exigences de configuration pour les machines GPU sont les suivantes : fréquence CPU d’au moins 3,0 GHz, GPU de la série Nvidia Geforce : 20xx, 30xx, 40xx, et au moins 16G de mémoire'
    }
  }
}