// 德语
export default {
  reward2: {
    desc: {
      title1: 'DeepLink-Knoten',
      text1: 'Nur Node-Besitzer können am CPU-Traffic-Mining und persönlichen GPU-Mining teilnehmen. Die Gesamtbelohnung beträgt 12% der DLC-Token, 4% für CPU-Traffic-Nodes und 8% für persönliche GPU-Nodes. Die Gesamtmenge der Token beträgt 12 Milliarden. Die Produktion pro Node hängt von der tatsächlich verkauften Anzahl der Nodes ab. Wenn alle verkauft werden, produziert jeder Node 100.000 DLC. Wenn nur 10% der Nodes verkauft werden, produziert jeder Node 1 Million DLC.',
      title2: 'Gesamtzahl der Knoten: 120.000 Keine zusätzliche Ausgabe',
      text2: 'Zusätzlicher Airdrop pro Node',
      text3: 'Minimale Menge an DLC, die pro Node abgebaut wird',
      text4: 'Startpreis',
      text5: 'Höchstpreis'
    },
    btn_text: 'Knoten kaufen',
    table: {
      tier: 'Stufe',
      license: 'Node-Lizenzpreis (USDT)',
      total_num: 'Gesamtlizenzen',
      total_usdt: 'Gesamt USDT',
      buy: 'Kaufen'
    },
    intro: {
      title: 'Einführung in das Node Mining',
      text1_1: 'Je mehr Benutzer das DeepLink-Netzwerk hat, desto mehr Netzwerkverkehr und Rechenleistung werden benötigt, was mehr CPU-Traffic-Maschinen und mehr GPU-Rechenmaschinen erfordert, um die Infrastruktur für das DeepLink-Netzwerk bereitzustellen. Dies ermöglicht die Dezentralisierung und hilft DeepLink, sich schneller zu entwickeln. Durch den Kauf einer Node-Lizenz haben Sie die Möglichkeit, am Mining teilzunehmen und Belohnungen zu verdienen. Haben Sie Fragen? Kommen Sie',
      text1_2: 'und',
      text1_3: 'unterhalten Sie sich mit uns oben.',
      text2: 'Jeder Knoten wird in Form eines NFT ausgegeben und erhält zusätzlich einen Airdrop von 30.000 DLC mit BSC-Kette. Der Airdrop beginnt vor der Notierung an der Börse, wobei 20 % des Airdrop-DLC sofort und 80 % über 8 Monate freigeschaltet werden. \n (DLC-Token-Prozess anzeigen: Öffnen Sie die Wallet und wählen Sie das BSC-Netzwerk aus. Wählen Sie „Token hinzufügen“ und geben Sie die Vertragsadresse ein: 0xD610Be30b06A3828A27D608a38F1E85faAFdEDcC)',
      text3: 'Nodes können vor dem 31. Oktober 2024 nicht an andere weiterverkauft werden.',
      text4: '25% der abgebauten Token werden sofort freigeschaltet, 75% werden linear über 150 Tage freigeschaltet.',
      text5: 'Wenn das Mining nicht innerhalb von 10 Tagen nach der Listung beginnt, werden die Belohnungen in Form von Airdrops verteilt, die alle 7 Tage stattfinden. Die Airdrop-Regeln sind die gleichen wie die Mining-Ertragsregeln, wobei die tägliche Gesamtproduktion 4,1 Millionen DLC beträgt, die gleichmäßig auf die verkauften Nodes verteilt wird. 25% werden sofort freigeschaltet, 75% werden linear über 150 Tage freigeschaltet. Wenn das Mining bereits begonnen hat, sind entsprechende Maschinen erforderlich, um Belohnungen zu erhalten.',
      text6: 'Anleger, die mehr als 1.000 Knoten einzeln kaufen, erhalten umfassenden Support für den Betrieb der Knoten und haben außerdem die Möglichkeit, sich an einen kooperativen Betreiber zu wenden, der sie beim Betrieb der Knoten unterstützt.'
    },
    question: {
      ques1: 'Wie viele Node-NFTs werden insgesamt verkauft?',
      ans1: 'Insgesamt 120.000',
      ques2: 'Wie viel kostet jedes Node-NFT?',
      ans2: 'Es ist in 10 Stufen unterteilt, mit Preisen von 30U bis 155U, verkauft auf der offiziellen Website, bis ausverkauft',
      ques3: 'Ist der Kauf eines Node-NFT eine einmalige Zahlung?',
      ans3: 'Ja, der Kauf eines Nodes ist eine einmalige Zahlung, es sind keine zusätzlichen Gebühren in der Zukunft erforderlich',
      ques4: 'Gibt es zusätzliche Airdrops beim Kauf eines Node-NFTs?',
      ans4: 'Ja, jeder Node-Besitzer erhält einen Airdrop von 30.000 DLC. Der Airdrop beginnt vor der Listung auf der Börse, wobei 20% der DLCs sofort freigeschaltet werden und 80% über 8 Monate freigeschaltet werden',
      ques5: 'Was ist der spezifische Mining-Mechanismus?',
      ans5: 'Die Gesamtbelohnung beträgt 12 Milliarden DLC. Die Gesamteinnahmen für Traffic-Nodes betragen 4 Milliarden DLC, und die Gesamteinnahmen für persönliche GPU-Maschinen-Nodes betragen 8 Milliarden DLC. Durch den Kauf eines Node-NFTs können Sie gleichzeitig an beiden Mining-Methoden teilnehmen. Die Belohnung für die ersten 4 Jahre beträgt 1,5 Milliarden DLC pro Jahr, mit einer Halbierung alle 4 Jahre. Die Gesamtbelohnung pro Tag beträgt 4,11 Millionen DLC, die nur an Node-NFT-Besitzer verteilt wird. 25% der abgebauten Token werden sofort freigeschaltet, 75% werden linear über 150 Tage freigeschaltet',
      ques6: 'Kann ein gekauftes Node-NFT gleichzeitig am CPU-Traffic-Mining und am persönlichen GPU-Mining teilnehmen?',
      ans6: 'Ja',
      ques7: 'Was ist ein CPU-Bandbreiten-Traffic-Node?',
      ans7: 'Der CPU-Bandbreiten-Traffic-Node übernimmt die Kernaufgabe der Berechnung und Weiterleitung des Traffics im DeepLink-Netzwerk. Durch das Mining-Modell ermöglicht DeepLink es mehr Menschen, teilzunehmen und Belohnungen zu verdienen. Die Gesamteinnahmen für Traffic-Nodes betragen 4 Milliarden DLC',
      ques8: 'Wie hoch ist die Gesamtbelohnung für Bandbreiten-Traffic-Nodes im ersten Jahr?',
      ans8: 'Die Belohnung im ersten Jahr beträgt 500 Millionen DLC, mit einer täglichen Belohnung von etwa 1,37 Millionen DLC',
      ques9: 'Wie steht die Bandbreite eines jeden CPU-Bandbreiten-Traffic-Nodes im Verhältnis zu seinen Einnahmen?',
      ans9: 'Die Basisbandbreite beträgt 10Mb Uplink-Bandbreite. Der Bandbreitenwert beträgt 10 bei 10Mb Uplink-Bandbreite. Die Bandbreite ist nur in Vielfachen von 10 gültig, zum Beispiel wird eine Uplink-Bandbreite von 45Mb als 40 im Bandbreitenwert berechnet. Die Bandbreite erhöht sich um 30% entsprechend der tatsächlichen Nutzung. Schließlich werden die Einnahmen entsprechend dem tatsächlichen Bandbreitenwert aufgeteilt',
      ques10: 'Sind die Konfigurationsanforderungen für Bandbreiten-Traffic-Nodes hoch? Gibt es Konfigurationsinformationen? Wie kann man am Mining teilnehmen?',
      ans10: 'Die Konfigurationsanforderungen sind nicht hoch. vCPU 1C + 2G Speicher + 50G Festplatte. Die Miete bei AWS kostet etwa 10 USD pro Monat, die Bandbreite wird nach tatsächlichem Verbrauch abgerechnet. Nachdem Sie eine CPU-VM bei AWS gemietet haben, installieren Sie das DeepLink-Traffic-Node-Mining-Programm, erstellen Sie eine Wallet-Adresse, übertragen Sie das Node-NFT in das Wallet und aktivieren Sie die Mining-Funktion, um am Mining teilzunehmen',
      ques11: 'Was ist ein persönlicher GPU-Maschinen-Node?',
      ans11: 'Individuen können ihre GPU-Computer anbieten, um am Mining teilzunehmen. Die GPU-Maschinen stellen Cloud-Gaming-Infrastrukturdienste für das DeepLink-Protokoll bereit. Die Gesamteinnahmen für persönliche GPU-Maschinen-Nodes betragen 8 Milliarden DLC',
      ques12: 'Wie hoch ist die Gesamtbelohnung für persönliche GPU-Maschinen-Nodes im ersten Jahr?',
      ans12: 'Die Belohnung im ersten Jahr beträgt 1 Milliarde DLC, mit einer täglichen Belohnung von etwa 2,74 Millionen DLC',
      ques13: 'Wie sind die Konfigurationsanforderungen für persönliche GPU-Maschinen-Nodes? Wie kann man am Mining teilnehmen?',
      ans13: 'Die Konfigurationsanforderungen für GPU-Maschinen sind: CPU-Frequenz von mindestens 3,0 GHz, GPU aus der Nvidia Geforce-Serie: 20xx, 30xx, 40xx, und mindestens 16G Speicher'
    }
  }
}